import Rest from '@/services/Rest';

/**
 * @typedef {LeadListaService}
 */
export default class LeadListaService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'lead/lista'
}